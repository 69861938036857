@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,700');

$font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;

$primary-color: #05C7DF;
$accent-color: #FF6357;

$main-background: linear-gradient(135deg, #2864DD 0%, $primary-color 100%);

$hub-software--primary-color: #FAC174;
$hub-software--primary-dark-color: darken(#F0B464, 20%);
$hub-software--hero-background: linear-gradient(135deg, $hub-software--primary-dark-color 0%, #F6C481 100%);

$hub-specs--primary-color: #F38B93;
$hub-specs--primary-dark-color: #EA7680;
$hub-specs--hero-background: linear-gradient(135deg, $hub-specs--primary-dark-color 0%, #F58B94 100%);

@import 'jekyll-theme-open-project';

.site-logo svg path {
  fill: white;
}

.site--hub.layout--home {
  > main {
    > .main-section {
      > .title {
        color: $main-font-color;
      }
    }
  }
  > .underlay.header {
    overflow-x: hidden;

    @media screen and (min-width: $bigscreen-breakpoint) {
      .hero {
        padding-bottom: 20px;
      }
    }
    @media screen and (min-width: $widescreen-breakpoint) {
      background:
        repeat-x bottom center/auto 30px url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='80' height='10' viewBox='0 0 80 10'><polygon fill='white' points='0,0 28,0 35,10 45,10 52,0 80,0 80,10 0,10'/></svg>"),
        $main-background;

      .hero {
        // To accommodate convex-concave SVG-based border
        padding-bottom: 80px;
      }
    }

    .hero .title {
      font-weight: normal;
    }

    .cta .button {
      border-radius: 4px;
    }
  }
}
